<template>
  <div class="pageContol listWrap templateList classCheckDetail">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a" v-if="this.$route.query.formStu != 'leader'">（财务）复核</a>
          <a href="javascript:;" @click="init" class="cur-a" v-else>领导（审批）</a>
        </span>
      </div>
      <div class="framePage-body ovy-a">
        <h3 style="padding-bottom:20px;">结算确认单</h3>
        <div >
          <iframe style="width:50%;height:500px" :src="pdfUrl" frameborder="0"></iframe>
        </div>
        <div class="el-div"></div>
        <h3 style="padding-bottom:20px;">审核记录</h3>
        <div>
          <el-table
            ref="multipleTable"
            :data="ListData"
            size="small"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="tableHeader"
            stripe
          >
            <el-table-column
              label="审核时间"
              align="center"
              show-overflow-tooltip
              prop="createTime"
            />
            <el-table-column
              label="审核人"
              align="center"
              show-overflow-tooltip
              prop="fullname"
            />
            <el-table-column
              label="审核状态"
              align="center"
              show-overflow-tooltip
              prop="auditType"
            >
              <template slot-scope="scope">
                <span>
                  {{ $setDictionary("FD_AUDIT_TYPE", scope.row.auditType) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="备注"
              align="center"
              show-overflow-tooltip
              prop="remark"
            >
            <template slot-scope="scope">
              {{scope.row.remark || '--'}}
            </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="el-div"></div>
        <div>
          <h3 style="margin:30px 0 20px;">备注</h3>
          <el-input
            type="textarea"
            placeholder="如果驳回请填写理由,不超过100字"
            v-model="auditReason"
            maxlength="100"
            show-word-limit
          ></el-input>
        </div>
        <div class="flexcc" style="padding:2rem">
          <el-button
           class="bgc-bv" style="margin:0 10px"
            @click="$router.back()"
            >取消</el-button
          >
          <el-button
            class="bgc-bv" style="margin:0 10px"
            @click="reject('10')"
            >驳回</el-button
          >
          <el-button
           class="bgc-bv" style="margin:0 10px"
            @click="reject('20')"
            >审核通过</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
export default {
  name: "evaluate/statementManagementFinanceToReview",
  components: {
  },
  mixins: [List],
  data() {
    return {
      pages:0,  //pdf分页 
       pdfDoc: null,  
      billId: this.$route.query.billId,
      ListData: [],
      payerAmountSum: "",
      settlementUserCountSum: "",
      searchData: {
        auditType: "",
        compId: "",
        projectCode: "",
        startTime: "",
        invoice: "",
      },
      areatreeList: [],
      CompanyList: [],
      statusList: [], //结算单状态list
      auditReason: "",
      pdfUrl:'',
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.getDataList()
    this.getpdf()
    // this.getareatree();
    // this.getStatusList();
  },
  methods: {
    /* 预览pdf */
    getpdf() {
      this.$post(this.$route.query.formStu == 'leader'? '/biz/settlement/bill/audit/preview':'/biz/settlement/bill/preview',{billId: this.$route.query.billId}).then(ret => {
          this.pdfUrl = ret.data
      }).catch(err => {
        return;
      })
    },
    getDataList() {
      this.$post("/biz/settlement/audit/list", { billId: this.billId })
        .then((ret) => {
          console.log(ret);
          this.ListData = ret.data;
        })
        .catch((err) => {
          return;
        });
    },
    /* 驳回&&通过 */
    reject(auditStatus) {
      let params = {
        billId:this.billId,
        auditStatus,
      }
      if(auditStatus == '10') {
        if(this.auditReason == '') {
          this.$message({
            message:'请输入驳回理由',
            type:'warning'
          });
          return false
        } else {
          if(this.auditReason) {
          params.remark = this.auditReason
        }
        }
        
      }
      if(this.$route.query.formStu == 'leader') {
          this.$post('/biz/settlement/audit/submitModifyBillAudit', params).then(ret => {
          console.log(ret);
          if(ret.status == '0') {
            this.$message({
              message:ret.message,
              type:'success'
            })
           this.$router.push({
            path: "/web/evaluate/statementManagementLeader",
            query: {
              refresh: true
            }
          });
          }
        }).catch(err => {
          return;
        })
      } else {
        this.$post('/biz/settlement/audit/submitFinancialReview', params).then(ret => {
        console.log(ret);
        if(ret.status == '0') {
          this.$message({
            message:ret.message,
            type:'success'
          })
          this.$router.push({
              path: "/web/evaluate/statementManagementFinance",
              query: {
                refresh: true
              }
            });
        }
      }).catch(err => {
        return;
      })
      }
      
    },
 
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree?areaLevel=1").then((ret) => {
        this.areatreeList = [...ret.data];
      });
    },
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight -
        (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.25 + 5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scoped>
.el-div {
  display: block;
  height: 1px;
  width: 100%;
  margin: 24px 0;
  border-bottom: 1px solid #dcdfe6;
}
.classCheckDetail {
  // th{
  //   background-color: #ffffff !important;
  //   color:#000 !important;
  //   border-top: 1px solid rgb(92, 107, 232);
  // }
  .btn-box {
    text-align: center;
    margin-top: 30px;
  }
  .el_form {
    .form-inline {
      display: inline-block;
      vertical-align: top;
      width: 50%;
    }
  }
  .framePage-body {
    width: 100%;
    overflow-y: auto;
  }
  .el-div {
    display: block;
    height: 1px;
    width: 100%;
    margin: 24px 0;
    border-bottom: 1px solid #dcdfe6;
  }
  .el-textarea {
    .el-textarea__inner {
      min-height: 100px !important;
      resize: none;
    }
  }
}
</style>


